import React from "react";

export default ({ color }) => (
  <svg width="327" height="335" viewBox="0 0 327 335" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M121.258 76.5727C123.088 75.4551 123.847 73.1631 123.018 71.1766C122.202 69.1902 120.056 68.0957 117.974 68.592L74.4481 79.0114C73.5691 79.2186 72.7855 79.6926 72.1891 80.3682L26.2888 132.326C25.5785 133.128 25.1952 134.158 25.1921 135.207C25.1912 135.517 25.2208 135.832 25.2898 136.138C25.5786 137.498 26.4972 138.637 27.7629 139.205L73.5253 159.845C74.8607 160.452 76.4212 160.339 77.6602 159.552C78.9037 158.764 79.6638 157.399 79.69 155.931C79.7004 155.384 81.0247 101.104 121.258 76.5727Z" fill="#F66E9C" />
    <path d="M96.1365 239.596C96.0229 247.965 94.0695 256.84 89.6733 264.016C82.9478 275.002 69.8291 281.168 57.7591 284.188C52.3535 285.538 46.5545 286.797 40.974 285.927C40.8822 285.914 40.5195 285.787 40.4365 285.831C44.636 283.633 47.9704 275.631 51.5712 270.431C55.1721 265.231 61.1066 260.896 61.1066 260.896C54.5079 258.431 47.9398 266.594 47.9398 266.594C47.3411 261.394 50.7409 252.396 50.7409 252.396C56.5399 239.194 63.3396 236.196 69.5407 232.596C75.7417 228.995 84.7395 227.395 84.7395 227.395C80.7454 225.193 75.545 225.805 71.1838 226.102C66.3724 226.429 61.5916 227.076 56.8414 227.893C43.1284 230.258 31.6397 240.156 26.5617 252.872C24.0664 259.117 23.1138 265.886 20.3039 271.982C17.4459 278.179 13.5042 283.375 9.68915 288.995C7.84501 291.704 6.11886 294.527 4.93022 297.591C3.81587 300.462 4.47574 303.971 2.94187 306.593C4.34027 304.194 9.74159 301.392 14.9463 300.186C20.1509 298.98 24.0752 302.127 24.0752 302.127C24.0752 302.127 15.239 305.693 8.53547 314.188C1.83189 322.683 0.153809 334.207 0.153809 334.207C5.33663 325.371 16.3752 322.692 25.561 320.14C35.8261 317.291 46.2355 314.992 56.5268 312.217C66.805 309.446 76.6157 304.189 84.0403 296.489C94.6419 285.49 100.756 270.802 100.699 256.347C100.699 256.347 100.34 245.99 96.1409 238.788C96.1409 239.063 96.1409 239.33 96.1365 239.596Z" fill="#73A77B" />
    <path d="M219.285 144.058C233.708 138.837 241.167 122.913 235.946 108.491C230.725 94.0682 214.801 86.6088 200.379 91.8297C185.956 97.0505 178.497 112.975 183.718 127.397C188.939 141.819 204.863 149.279 219.285 144.058Z" fill={color} />
    <path d="M235.335 124.969C247.135 120.698 253.238 107.669 248.967 95.8688C244.695 84.0687 231.666 77.9655 219.866 82.2371C208.066 86.5087 201.963 99.5375 206.234 111.338C210.506 123.138 223.535 129.241 235.335 124.969Z" fill="#FFB000" />
    <path d="M251.63 207.049C252.753 205.222 255.047 204.47 257.031 205.305C259.015 206.127 260.103 208.277 259.601 210.357L249.052 253.851C248.842 254.73 248.365 255.512 247.688 256.106L195.593 301.852C194.789 302.56 193.758 302.94 192.709 302.94C192.399 302.94 192.084 302.909 191.778 302.839C190.419 302.546 189.283 301.624 188.719 300.357L168.215 254.533C167.612 253.196 167.73 251.636 168.521 250.399C169.312 249.158 170.68 248.402 172.148 248.38C172.695 248.372 226.979 247.209 251.63 207.049Z" fill="#F66E9C" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.04 211.125C127.972 218.515 138.425 226.831 141.716 227.766C144.049 227.028 149.166 224.31 153.947 221.578C145.631 215.723 136.087 208.482 129.383 201.778L125.857 198.247C119.153 191.548 111.912 181.999 106.061 173.683C103.325 178.464 100.611 183.586 99.8685 185.924C100.804 189.214 109.129 199.667 116.619 207.708L120.04 211.125ZM213.423 206.611C224.61 199.838 235.697 191.806 246.36 182.747C290.108 145.584 336.692 76.7086 324.792 4.39382L323.237 2.8381C250.913 -9.06141 182.051 37.5315 144.888 81.2752C135.829 91.938 127.797 103.025 121.015 114.225C120.875 114.457 106.738 138.125 102.836 153.122C106.568 159.018 120.491 180.522 132.036 192.068L135.563 195.599C147.121 207.157 168.622 221.072 174.512 224.799C189.506 220.897 213.178 206.76 213.423 206.611ZM200.296 128.573C215.151 143.336 240.164 142.252 256.165 126.152C272.165 110.052 273.094 85.0325 258.239 70.2695C243.385 55.5065 218.371 56.5903 202.371 72.6904C186.37 88.7905 185.441 113.81 200.296 128.573Z" fill="#333333" />
  </svg>



);
